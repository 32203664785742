import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./UserContext"; // Import the user context
import "./Homepage.css";

const Homepage = () => {
    const { user, setUser } = useUser(); // Get user and setUser from context
    const navigate = useNavigate(); // Use React Router for navigation

    const handleLogout = () => {
        setUser(null); // Clear user from context
        navigate("/login"); // Redirect to login page
    };

    // State for dropdown menus
    const [dropdownOpen, setDropdownOpen] = useState({});

    // Function to toggle dropdown visibility
    const toggleDropdown = (key, isOpen) => {
        setDropdownOpen((prev) => ({ ...prev, [key]: isOpen }));
    };

    // Sports dropdown data
    const sports = [
        { name: "NFL", links: ["Teams", "Scores", "Standings"] },
        { name: "NBA", links: ["Teams", "Scores", "Standings"] },
        { name: "MLB", links: ["Teams", "Scores", "Standings"] },
        { name: "NHL", links: ["Teams", "Scores", "Standings"] },
        { name: "Soccer", links: ["Teams", "Scores", "Standings"] },
        { name: "NCAA", links: ["Teams", "Scores", "Standings"] },
        { name: "Fantasy", links: ["Draft", "Tips", "Leagues", "Create Your Organization"], path: "/fantasy" },
    ];

    // Fetch news articles on component mount
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch("/api/fetchNews");
                if (!response.ok) {
                    throw new Error("Failed to fetch news articles");
                }
                const data = await response.json();
                setArticles(data.articles); // Set fetched articles
            } catch (error) {
                console.error("Error fetching news:", error);
                setErrorMessage("Failed to load news articles."); // Set error message
            } finally {
                setLoading(false); // Set loading state to false
            }
        };

        fetchNews();
    }, []);

    return (
        <div className="homepage-container">
            {/* Navbar Section */}
            <nav className="navbar">
                <div className="logo">MILKYSPORTS</div>
                <ul className="sports-links">
                    {sports.map((sport) => (
                        <li
                            key={sport.name}
                            className="dropdown"
                            onMouseEnter={() => toggleDropdown(sport.name, true)}
                            onMouseLeave={() => toggleDropdown(sport.name, false)}
                        >
                            <button className="dropdown-button">{sport.name}</button>
                            {dropdownOpen[sport.name] && (
                                <div
                                    className="dropdown-hover-zone"
                                    onMouseEnter={() => toggleDropdown(sport.name, true)} // Prevent hiding on hover
                                    onMouseLeave={() => toggleDropdown(sport.name, false)} // Allow hiding on leave
                                >
                                    <div className="dropdown-menu">
                                        <div className="dropdown-section">
                                            <h4>{sport.name} Options</h4>
                                            <ul>
                                                {sport.links.map((link) => (
                                                    <li key={link}>
                                                        <Link
                                                            to={
                                                                link === "Create Your Organization"
                                                                    ? "/create-organization"
                                                                    : `/${sport.name.toLowerCase()}/${link.toLowerCase()}`
                                                            }
                                                        >
                                                            {link}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
                <div className="auth-buttons">
                    {user ? (
                        <>
                            <span className="username">Welcome, {user.username}</span>
                            <button className="logout-button" onClick={handleLogout}>
                                Log out
                            </button>
                        </>
                    ) : (
                        <>
                            <button className="login-button" onClick={() => navigate("/login")}>
                                Log in
                            </button>
                            <button className="signup-button" onClick={() => navigate("/create-account")}>
                                Sign up
                            </button>
                        </>
                    )}
                </div>
            </nav>

            {/* Hero Section */}
            <div className="hero-section">
                <div className="video-overlay">
                    <video autoPlay loop muted playsInline className="background-video">
                        <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {/* Overlay for readability */}
                    <div className="hero-overlay"></div>
                    <div className="hero-content">
                        <h1>Welcome to MilkySports</h1>
                        <p>Your go-to platform for fantasy sports!</p>
                        <button
                            className="button"
                            onClick={() => navigate("/fantasy")}
                        >
                            Get Started
                        </button>
                    </div>
                </div>
            </div>

            {/* News Section */}
            <div className="news-section">
                <h2>Recent News</h2>
                {loading ? (
                    <p>Loading sports news...</p>
                ) : errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                ) : (
                    <div className="news-cards">
                        {articles.slice(0, 5).map((article, index) => (
                            <div className="news-card" key={index}>
                                {article.image && (
                                    <img
                                        src={article.image}
                                        alt={article.title}
                                        className="news-image"
                                    />
                                )}
                                <div className="news-content">
                                    <h3 className="news-title">{article.title}</h3>
                                    <p className="news-description">{article.description}</p>
                                    <a
                                        href={article.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="news-link"
                                    >
                                        Read more
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Homepage;
