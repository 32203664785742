// JoinLeague.js

import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext'; // Import user context
import { createClient } from '@supabase/supabase-js'; // Import Supabase client
import { useLocation } from 'react-router-dom';
import './JoinLeague.css';

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const JoinLeague = () => {
  const { user } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialCode = queryParams.get('code') || '';

  const [invitationCode, setInvitationCode] = useState(initialCode);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (initialCode) {
      handleJoinLeague();
    }
  }, [initialCode]);

  const handleJoinLeague = async (e) => {
    if (e) e.preventDefault(); // Prevent form submission if manually triggered

    if (!invitationCode.trim()) {
      setErrorMessage('Please enter a valid invitation code.');
      return;
    }

    try {
      // Fetch the league using the invitation code
      const { data: leagueData, error: leagueError } = await supabase
        .from('fantasy_leagues')
        .select('*')
        .eq('invitation_code', invitationCode)
        .single();

      if (leagueError || !leagueData) {
        setErrorMessage('Invalid invitation code. Please try again.');
        return;
      }

      // Check if the user is already a member
      const { data: existingMember } = await supabase
        .from('league_members')
        .select('*')
        .eq('league_id', leagueData.league_id)
        .eq('user_id', user.id)
        .single();

      if (existingMember) {
        setErrorMessage('You are already a member of this league.');
        return;
      }

      // Ensure the league has capacity
      const { data: members, error: membersError } = await supabase
        .from('league_members')
        .select('*')
        .eq('league_id', leagueData.league_id);

      if (membersError) {
        setErrorMessage('Failed to join league. Please try again.');
        return;
      }

      if (members.length >= leagueData.num_teams) {
        setErrorMessage('This league is full and cannot accept more members.');
        return;
      }

      // Add the user to the league members table
      const { error: memberError } = await supabase
        .from('league_members')
        .insert([
          {
            league_id: leagueData.league_id,
            user_id: user.id,
            joined_at: new Date().toISOString(),
          },
        ]);

      if (memberError) {
        setErrorMessage('Failed to join league. Please try again.');
        console.error('Error joining league:', memberError);
      } else {
        setSuccessMessage(`Successfully joined the league: ${leagueData.league_name}`);
        setErrorMessage(''); // Clear any previous errors
      }
    } catch (error) {
      console.error('Error joining league:', error);
      setErrorMessage('Failed to join league. Please try again.');
    }
  };

  return (
    <div className="join-league">
      <h1>Join a Fantasy League</h1>
      <form onSubmit={handleJoinLeague} className="join-league-form">
        <div className="form-group">
          <label>Invitation Code:</label>
          <input
            type="text"
            value={invitationCode}
            onChange={(e) => setInvitationCode(e.target.value)}
            placeholder="Enter invitation code"
            required
          />
        </div>
        <button type="submit" className="join-button">Join League</button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default JoinLeague;
