import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import bcrypt from 'bcryptjs';
import { useUser } from './UserContext';
import './Login.css';

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const { setUser } = useUser();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    if (!username || !password) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    setIsLoading(true); // Set loading state

    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('username', username.toLowerCase()) // Case-insensitive check
        .single();

      if (error || !data) {
        setErrorMessage('Invalid username or password.');
        return;
      }

      const passwordMatch = await bcrypt.compare(password, data.password);

      if (!passwordMatch) {
        setErrorMessage('Invalid username or password.');
        return;
      }

      // Set user in context
      setUser({
        id: data.id,
        username: data.username,
        email: data.email,
      });

      navigate('/'); // Redirect to homepage
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  // Automatically clear error messages after 5 seconds
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [errorMessage]);

  return (
    <div className="login-wrapper">
      <div className="login-overlay"></div>
      <div className="login-form-container">
        <h2>Login</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            disabled={isLoading} // Disable input when loading
          />
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLoading} // Disable input when loading
          />
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'} {/* Dynamic button text */}
          </button>
        </form>
        <p className="link">
          Don’t have an account? <Link to="/create-account">Register</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
