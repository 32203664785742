// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './components/UserContext';
import Homepage from './components/Homepage';
import Login from './components/Login';
import CreateAccount from './components/CreateAccount';
import NFLPage from './components/NFLPage';
import Fantasy from './components/Fantasy';
import EmailVerification from './components/EmailVerification'; // Importing the VerifyEmail component
import CreateOrganization from './components/CreateOrganization';
import CreateFantasyLeague from './components/CreateFantasyLeague';
import OrganizationManagement from './components/OrganizationManagement';
import JoinLeague from './components/JoinLeague';

const App = () => {
    return (
        <UserProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/create-account" element={<CreateAccount />} />
                    <Route path="/nfl" element={<NFLPage />} />
                    <Route path="/fantasy" element={<Fantasy />} />
                    <Route path="/verify" element={<EmailVerification />} /> {/* New route for email verification */}
                    <Route path="/create-organization" element={<CreateOrganization />} />
                    <Route path="/create-league" element={<CreateFantasyLeague />} />
                    <Route path="/organization/:organizationId" element={<OrganizationManagement />} />
                    <Route path="/join-league" element={<JoinLeague />} />
                </Routes>
            </Router>
        </UserProvider>
    );
};

export default App;
