import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { createClient } from '@supabase/supabase-js';
import './CreateOrganization.css';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const CreateOrganization = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [orgName, setOrgName] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [sports, setSports] = useState([{ sportName: '', teams: [''] }]);
  const [players, setPlayers] = useState({});
  const [playerInputs, setPlayerInputs] = useState({}); // New state for individual inputs
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleNextStep = () => {
    if (currentStep === 1 && !orgName.trim()) {
      setErrorMessage('Organization Name is required.');
      return;
    }
    if (currentStep === 2 && sports.some((sport) => !sport.sportName.trim())) {
      setErrorMessage('Each sport must have a name.');
      return;
    }
    setErrorMessage('');
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setErrorMessage('');
    setCurrentStep(currentStep - 1);
  };

  const handleSportChange = (index, value) => {
    const updatedSports = [...sports];
    updatedSports[index].sportName = value;
    setSports(updatedSports);
  };

  const handleAddSport = () => {
    setSports([...sports, { sportName: '', teams: [''] }]);
  };

  const handleTeamChange = (sportIndex, teamIndex, value) => {
    const updatedSports = [...sports];
    updatedSports[sportIndex].teams[teamIndex] = value;
    setSports(updatedSports);
  };

  const handleAddTeam = (sportIndex) => {
    const updatedSports = [...sports];
    updatedSports[sportIndex].teams.push('');
    setSports(updatedSports);
  };

  const handlePlayerInputChange = (teamKey, value) => {
    setPlayerInputs((prevInputs) => ({
      ...prevInputs,
      [teamKey]: value,
    }));
  };

  const handleAddPlayer = (teamKey) => {
    if (!playerInputs[teamKey]?.trim()) return;
    setPlayers((prevPlayers) => ({
      ...prevPlayers,
      [teamKey]: [...(prevPlayers[teamKey] || []), playerInputs[teamKey].trim()],
    }));
    setPlayerInputs((prevInputs) => ({
      ...prevInputs,
      [teamKey]: '',
    }));
  };

  const handleCreateOrganization = async () => {
    setLoading(true);
    try {
      const { data: orgData, error: orgError } = await supabase
        .from('organizations')
        .insert([{ name: orgName, owner_id: user.id, access_code: accessCode }])
        .select()
        .single();

      if (orgError || !orgData) throw new Error('Failed to create organization.');

      const organizationId = orgData.organization_id;

      for (const sport of sports) {
        const { data: sportData, error: sportError } = await supabase
          .from('sports')
          .insert([{ organization_id: organizationId, sport_name: sport.sportName }])
          .select()
          .single();

        if (sportError || !sportData) throw new Error('Failed to create sport.');

        const sportId = sportData.sport_id;

        for (const teamName of sport.teams) {
          const { data: teamData, error: teamError } = await supabase
            .from('orgteams')
            .insert([{ organization_id: organizationId, sport_id: sportId, name: teamName }])
            .select()
            .single();

          if (teamError || !teamData) throw new Error('Failed to create team.');

          const teamId = teamData.team_id;
          const teamKey = `sport-${sports.indexOf(sport)}-team-${sport.teams.indexOf(teamName)}`;
          const teamPlayers = players[teamKey] || [];
          for (const playerName of teamPlayers) {
            const { error: playerError } = await supabase
              .from('orgteamplayers')
              .insert([{ organization_id: organizationId, team_id: teamId, player_name: playerName }]);

            if (playerError) throw new Error('Failed to add players.');
          }
        }
      }

      setSuccessMessage('Organization created successfully!');
      setTimeout(() => navigate('/fantasy'), 3000);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-organization-container">
      <h1 className="create-organization-header">Create Your Organization</h1>

      <div className="create-organization-step-indicators">
        <div className={`create-organization-step ${currentStep >= 1 ? 'active' : ''}`}>1. Organization Details</div>
        <div className={`create-organization-step ${currentStep >= 2 ? 'active' : ''}`}>2. Sports & Teams</div>
        <div className={`create-organization-step ${currentStep === 3 ? 'active' : ''}`}>3. Add Players</div>
        <div className={`create-organization-step ${currentStep === 4 ? 'active' : ''}`}>4. Confirmation</div>
      </div>

      {currentStep === 1 && (
        <div>
          <div className="create-organization-form-group">
            <label>Organization Name:</label>
            <input
              type="text"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              placeholder="Enter your organization name"
              required
            />
          </div>
          <div className="create-organization-form-group">
            <label>Access Code (optional):</label>
            <input
              type="text"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              placeholder="Enter a unique code for private sports"
            />
          </div>
          <div className="create-organization-button-group">
            <button onClick={handleNextStep} className="create-organization-next-button">Next</button>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <>
          {sports.map((sport, sportIndex) => (
            <div className="create-organization-sport-section" key={sportIndex}>
              <div className="create-organization-form-group">
                <label>Sport {sportIndex + 1} Name:</label>
                <input
                  type="text"
                  value={sport.sportName}
                  onChange={(e) => handleSportChange(sportIndex, e.target.value)}
                  placeholder="Enter sport name"
                />
              </div>
              {sport.teams.map((team, teamIndex) => (
                <div className="create-organization-form-group" key={teamIndex}>
                  <label>Team {teamIndex + 1} Name:</label>
                  <input
                    type="text"
                    value={team}
                    onChange={(e) => handleTeamChange(sportIndex, teamIndex, e.target.value)}
                    placeholder="Enter team name"
                  />
                </div>
              ))}
              <button onClick={() => handleAddTeam(sportIndex)} className="create-organization-add-button">Add Another Team</button>
            </div>
          ))}
          <button onClick={handleAddSport} className="create-organization-add-button">Add Another Sport</button>
          <div className="create-organization-button-group">
            <button onClick={handlePreviousStep} className="create-organization-back-button">Back</button>
            <button onClick={handleNextStep} className="create-organization-next-button">Next</button>
          </div>
        </>
      )}

      {currentStep === 3 && (
        <div className="create-organization-add-players">
          {sports.map((sport, sportIndex) => (
            sport.teams.map((team, teamIndex) => {
              const teamKey = `sport-${sportIndex}-team-${teamIndex}`;
              return (
                <div key={teamKey} className="create-organization-form-group">
                  <label>Add Player to Team {team}:</label>
                  <input
                    type="text"
                    value={playerInputs[teamKey] || ''}
                    onChange={(e) => handlePlayerInputChange(teamKey, e.target.value)}
                    placeholder="Enter player name"
                  />
                  <button
                    onClick={() => handleAddPlayer(teamKey)}
                    className="create-organization-add-button"
                  >
                    Add Player
                  </button>
                </div>
              );
            })
          ))}
          <div className="create-organization-button-group">
            <button onClick={handlePreviousStep} className="create-organization-back-button">Back</button>
            <button onClick={handleNextStep} className="create-organization-next-button">Next</button>
          </div>
        </div>
      )}

      {currentStep === 4 && (
        <div className="create-organization-confirmation">
          <h2>Review Your Organization</h2>
          <p><strong>Organization Name:</strong> {orgName}</p>
          <p><strong>Access Code:</strong> {accessCode || 'N/A'}</p>
          {sports.map((sport, sportIndex) => (
            <div key={sportIndex}>
              <h3>{sport.sportName}</h3>
              {sport.teams.map((team, teamIndex) => (
                <div key={teamIndex}>
                  <h4>{team}</h4>
                  <ul>
                    {(players[`sport-${sportIndex}-team-${teamIndex}`] || []).map((player, idx) => (
                      <li key={idx}>{player}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
          <button onClick={handleCreateOrganization} className="create-organization-next-button">
            {loading ? 'Creating...' : 'Create Organization'}
          </button>
        </div>
      )}

      {successMessage && <p className="create-organization-success-message">{successMessage}</p>}
      {errorMessage && <p className="create-organization-error-message">{errorMessage}</p>}
    </div>
  );
};

export default CreateOrganization;
