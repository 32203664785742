// CreateFantasyLeague.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext'; // Import user context
import { createClient } from '@supabase/supabase-js'; // Import Supabase client
import { v4 as uuidv4 } from 'uuid'; // Import UUID for generating tokens
import './CreateFantasyLeague.css'; // Import CSS for this component

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL, 
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const CreateFantasyLeague = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  // Form steps
  const [currentStep, setCurrentStep] = useState(1);

  // Form fields
  const [leagueName, setLeagueName] = useState('');
  const [selectedSport, setSelectedSport] = useState(null);
  const [publicSports, setPublicSports] = useState([]);
  const [customSports, setCustomSports] = useState([]);
  const [accessCode, setAccessCode] = useState('');
  const [organizationName, setOrganizationName] = useState('');

  // Football-specific fields
  const [numTeams, setNumTeams] = useState(8);
  const [draftType, setDraftType] = useState('Live');
  const [scoringSystem, setScoringSystem] = useState('Standard');

  // Messages
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [invitationCode, setInvitationCode] = useState('');

  // Email Invitation
  const [email, setEmail] = useState('');
  const [emailStatus, setEmailStatus] = useState('');

  // Fetch all public (non-custom) sports initially
  useEffect(() => {
    const fetchPublicSports = async () => {
      const { data, error } = await supabase
        .from('sports')
        .select('*')
        .eq('is_custom', false);

      if (!error) setPublicSports(data);
      else console.error("Error fetching public sports:", error);
    };
    fetchPublicSports();
  }, []);

  // Handle access code submission
  const handleAccessCodeSubmit = async (e) => {
    e.preventDefault();

    if (!accessCode.trim()) {
      setErrorMessage("Please enter a valid access code.");
      return;
    }

    // Fetch organization with the provided access code
    const { data: orgData, error: orgError } = await supabase
      .from('organizations')
      .select('organization_id, name')
      .eq('access_code', accessCode)
      .single();

    if (orgError || !orgData) {
      setErrorMessage("Invalid access code. Please try again.");
      return;
    }

    const orgId = orgData.organization_id;
    setOrganizationName(orgData.name);

    // Fetch custom sports associated with the organization
    const { data: customSportsData, error: sportsError } = await supabase
      .from('sports')
      .select('*')
      .eq('organization_id', orgId);

    if (!sportsError) {
      setCustomSports(customSportsData);
      setErrorMessage(''); // Clear error message if any
    } else {
      console.error("Error fetching custom sports:", sportsError);
      setErrorMessage("Failed to retrieve sports. Please try again.");
    }
  };

  // Handle league creation
  const handleCreateLeague = async (e) => {
    e.preventDefault();
    if (!leagueName || !selectedSport) {
      setErrorMessage("Please enter a league name and select a sport.");
      return;
    }

    try {
      // Generate a unique invitation code
      const invitationCodeGenerated = uuidv4();

      // Insert into fantasy_leagues table with additional settings and invitation code
      const { data, error } = await supabase
        .from('fantasy_leagues')
        .insert([{ 
          league_name: leagueName,
          sport_id: selectedSport,
          owner_id: user.id,
          num_teams: numTeams,
          draft_type: draftType,
          scoring_system: scoringSystem,
          invitation_code: invitationCodeGenerated,
        }])
        .select()
        .single();

      if (error) {
        setErrorMessage("Failed to create league. Please try again.");
        console.error("Error creating league:", error);
      } else {
        setSuccessMessage("Fantasy league created successfully!");

        // Store the invitation code in state to display it
        setInvitationCode(invitationCodeGenerated);

        // Optionally navigate to a different page or reset the form
        // setTimeout(() => navigate('/fantasy'), 3000);
      }
    } catch (error) {
      console.error("Error creating league:", error);
      setErrorMessage("Failed to create league. Please try again.");
    }
  };

  // Proceed to next step
  const handleNextStep = () => {
    if (currentStep === 1) {
      if (!leagueName || !selectedSport) {
        setErrorMessage("Please enter a league name and select a sport.");
        return;
      }
      setErrorMessage('');
      const sportName = getSelectedSportName().toLowerCase();
      if (sportName.includes('football') || sportName.includes('nfl')) {
        setCurrentStep(2);
      } else {
        setCurrentStep(3); // Skip to confirmation if not football
      }
    } else if (currentStep === 2) {
      // You can add validation for football-specific settings here
      setCurrentStep(3);
    }
  };

  // Go back to previous step
  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // Get selected sport name
  const getSelectedSportName = () => {
    const sport = [...publicSports, ...customSports].find(s => s.sport_id === selectedSport);
    return sport ? sport.sport_name : '';
  };

  // Handle sending email invitation
  const sendInvitationEmail = async (receiverEmail, invitationLink, leagueName) => {
    try {
      const response = await fetch('/api/sendInvitationEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ receiverEmail, invitationLink, leagueName }),
      });

      if (!response.ok) {
        throw new Error('Failed to send invitation email');
      }

      const data = await response.json();
      console.log(data.message);
      setEmailStatus("Invitation email sent successfully!");
    } catch (error) {
      console.error('Error sending invitation email:', error.message);
      setEmailStatus("Failed to send email. Please try again.");
    }
  };

  const handleSendEmail = async () => {
    if (!email) {
      setEmailStatus("Please enter an email address.");
      return;
    }

    const invitationLink = `https://milkysports.com/join-league?code=${invitationCode}`;

    await sendInvitationEmail(email, invitationLink, leagueName);
  };

  return (
    <div className="create-fantasy-league">
      <h1>Create Fantasy League</h1>

      {/* Step Indicators */}
      <div className="step-indicators">
        <div className={`step ${currentStep >= 1 ? 'active' : ''}`}>1. League Details</div>
        <div className={`step ${currentStep >= 2 ? 'active' : ''}`}>2. { (getSelectedSportName().toLowerCase().includes('football') || getSelectedSportName().toLowerCase().includes('nfl')) ? 'Football Settings' : 'Settings' }</div>
        <div className={`step ${currentStep === 3 ? 'active' : ''}`}>3. Confirmation</div>
      </div>

      {/* Step 1: League Details */}
      {currentStep === 1 && (
        <>
          {/* Access Code Submission */}
          <div className="access-code-section">
            <label>Have an Access Code for Custom Sports?</label>
            <form onSubmit={handleAccessCodeSubmit}>
              <div className="access-code-input">
                <input 
                  type="text" 
                  value={accessCode} 
                  onChange={(e) => setAccessCode(e.target.value)} 
                  placeholder="Enter access code" 
                />
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>

          {/* League Details Form */}
          <div className="league-form">
            <div className="form-group">
              <label>League Name:</label>
              <input 
                type="text" 
                value={leagueName} 
                onChange={(e) => setLeagueName(e.target.value)} 
                required 
                placeholder="Enter your league name"
              />
            </div>

            <div className="form-group">
              <label>Select a Sport:</label>
              <div className="sports-container">
                {publicSports.map(sport => (
                  <div 
                    key={sport.sport_id}
                    onClick={() => setSelectedSport(sport.sport_id)}
                    className={`sport-bubble ${selectedSport === sport.sport_id ? 'selected' : ''}`}
                  >
                    {sport.sport_name}
                  </div>
                ))}
              </div>
            </div>

            {/* Display Custom Sports */}
            {customSports.length > 0 && (
              <div className="form-group">
                <h3>{organizationName} Sports</h3>
                <div className="sports-container">
                  {customSports.map(sport => (
                    <div 
                      key={sport.sport_id}
                      onClick={() => setSelectedSport(sport.sport_id)}
                      className={`sport-bubble ${selectedSport === sport.sport_id ? 'selected' : ''}`}
                    >
                      {sport.sport_name}
                    </div>
                  ))}
                </div>
              </div>
            )}

            <button onClick={handleNextStep} className="next-button">Next</button>
          </div>
        </>
      )}

      {/* Step 2: Football-Specific Settings */}
      {currentStep === 2 && (
        <div className="football-settings">
          <h2>Football Settings</h2>
          <div className="form-group">
            <label>Number of Teams:</label>
            <select value={numTeams} onChange={(e) => setNumTeams(parseInt(e.target.value))}>
              {[8, 10, 12, 14, 16].map(num => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Draft Type:</label>
            <select value={draftType} onChange={(e) => setDraftType(e.target.value)}>
              <option value="Live">Live Draft</option>
              <option value="Auto">Auto-Pick Draft</option>
              <option value="Snake">Snake Draft</option>
            </select>
          </div>
          <div className="form-group">
            <label>Scoring System:</label>
            <select value={scoringSystem} onChange={(e) => setScoringSystem(e.target.value)}>
              <option value="Standard">Standard</option>
              <option value="PPR">Point Per Reception (PPR)</option>
              <option value="Custom">Custom</option>
            </select>
          </div>

          <div className="button-group">
            <button onClick={handlePreviousStep} className="back-button">Back</button>
            <button onClick={handleNextStep} className="next-button">Next</button>
          </div>
        </div>
      )}

      {/* Step 3: Confirmation */}
      {currentStep === 3 && (
        <div className="confirmation">
          <h2>Confirm Your League Details</h2>
          <p><strong>League Name:</strong> {leagueName}</p>
          <p><strong>Sport:</strong> {getSelectedSportName()}</p>
          {(getSelectedSportName().toLowerCase().includes('football') || getSelectedSportName().toLowerCase().includes('nfl')) && (
            <>
              <p><strong>Number of Teams:</strong> {numTeams}</p>
              <p><strong>Draft Type:</strong> {draftType}</p>
              <p><strong>Scoring System:</strong> {scoringSystem}</p>
            </>
          )}
          <div className="button-group">
            <button onClick={handlePreviousStep} className="back-button">Back</button>
            <button onClick={handleCreateLeague} className="create-button">Create League</button>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="success-message">
          <p>{successMessage}</p>
          {invitationCode && (
            <div className="invitation-code">
              <p><strong>Invitation Link:</strong></p>
              <input
                type="text"
                readOnly
                value={`https://milkysports.com/join-league?code=${invitationCode}`}
                onClick={(e) => e.target.select()}
                className="invitation-link"
              />
              <button onClick={() => navigator.clipboard.writeText(`https://milkysports.com/join-league?code=${invitationCode}`)}>
                Copy Link
              </button>
              <p>Share this link with others to let them join your league!</p>

              {/* Email Invitation */}
              <div className="email-invitation">
                <h3>Send Invitation via Email</h3>
                <div className="email-form">
                  <input
                    type="email"
                    placeholder="Enter email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button onClick={handleSendEmail}>Send Email</button>
                </div>
                {emailStatus && <p className="email-status">{emailStatus}</p>}
              </div>
            </div>
          )}
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default CreateFantasyLeague;
