// src/components/NFLPage.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext'; // Import the user context
import './NFLPage.css'; // Ensure you have this CSS file

const NFLPage = () => {
    const { user, setUser } = useUser(); // Get user and setUser from context
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate(); // Use React Router for navigation

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        setUser(null);  // Clear user from context
        navigate('/login');  // Redirect to login page
    };

    return (
        <div className="nfl-page">
            <nav className="navbar">
                <div className="logo">MilkySports</div>
                <ul className="sports-links">
                    <li><Link to="/nfl">NFL</Link></li>
                    <li><Link to="/nba">NBA</Link></li>
                    <li><Link to="/mlb">MLB</Link></li>
                    <li><Link to="/nhl">NHL</Link></li>
                    <li><Link to="/soccer">Soccer</Link></li>
                    <li><Link to="/ncaa">NCAA</Link></li>
                </ul>
                <div className="dropdown">
                    <button className="dropdown-button" onClick={toggleDropdown}>
                        <img src="./images/profile.png" alt="Profile" />
                    </button>
                    {dropdownOpen && (
                        <ul className="dropdown-menu">
                            {user ? (
                                <>
                                    <li>Welcome, {user.username}!</li>
                                    <li onClick={handleLogout}>Log Out</li>
                                </>
                            ) : (
                                <>
                                    <li><Link to="/login">Login</Link></li>
                                    <li><Link to="/create-account">Create an account</Link></li>
                                </>
                            )}
                        </ul>
                    )}
                </div>
            </nav>

            <nav className="nfl-navbar">
                <ul className="nfl-links">
                    <li><Link to="/nfl/news">News</Link></li>
                    <li><Link to="/nfl/scores">Scores</Link></li>
                    <li><Link to="/nfl/standings">Standings</Link></li>
                    <li><Link to="/nfl/fantasy">Fantasy</Link></li>
                </ul>
            </nav>

            <div className="content">
                <h1>NFL Page</h1>
                <p>Welcome to the NFL section!</p>
            </div>
        </div>
    );
};

export default NFLPage;
