import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import bcrypt from 'bcryptjs';
import { v4 as uuidv4 } from 'uuid'; // Import UUID for generating tokens
import './CreateAccount.css';

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const CreateAccount = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!firstName || !lastName || !username || !email || !password || !verifyPassword) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    // Check if passwords match
    if (password !== verifyPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    setLoading(true); // Start loading state

    try {
      // Convert username to lowercase
      const lowercaseUsername = username.toLowerCase();

      // Hash the password before storing it
      const hashedPassword = await bcrypt.hash(password, 10);
      const verificationToken = uuidv4(); // Generate a unique verification token

      // Insert the user into the Supabase 'users' table
      const { error } = await supabase.from('users').insert([
        {
          first_name: firstName,
          last_name: lastName,
          username: lowercaseUsername,
          email,
          password: hashedPassword,
          verified: false,
          verification_token: verificationToken, // Store the generated token
        },
      ]);

      if (error) {
        setErrorMessage(error.message || 'Account creation failed.');
      } else {
        // Send verification email
        await sendVerificationEmail(email, verificationToken);

        setSuccessMessage(
          'Account created successfully! Please check your email to verify your account.'
        );

        // Redirect to login after 3 seconds
        setTimeout(() => navigate('/login'), 3000);
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  const sendVerificationEmail = async (email, token) => {
    try {
      const response = await fetch('/api/sendVerificationEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ receiverEmail: email, verificationToken: token }),
      });

      if (!response.ok) {
        throw new Error('Failed to send verification email');
      }

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error('Error sending verification email:', error.message);
    }
  };

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  return (
    <div className="create-account-wrapper">
      <div className="create-account-form-container">
        <h2>Create an Account</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <label>First Name:</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            disabled={loading}
          />
          <label>Last Name:</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            disabled={loading}
          />
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            disabled={loading}
          />
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
          <label>Verify Password:</label>
          <input
            type="password"
            value={verifyPassword}
            onChange={(e) => setVerifyPassword(e.target.value)}
            required
            disabled={loading}
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Creating...' : 'Create Account'}
          </button>
        </form>
        <p className="link">
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default CreateAccount;
