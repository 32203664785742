import React, { useState, useEffect } from "react";
import "./FantasyLeagueManagement.css";
import { FiSettings, FiUserPlus } from "react-icons/fi";
import { MdOutlineSportsFootball, MdLeaderboard } from "react-icons/md";
import { createClient } from "@supabase/supabase-js";

// Supabase client initialization
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const FantasyLeagueManagement = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [leagueMembers, setLeagueMembers] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);
  const [inviteLink, setInviteLink] = useState("");
  const leagueId = 1; // Replace with the actual league ID
  const leagueName = "Milky League";
  const owner = "You"; // Replace with dynamic data if necessary
  const sport = "Football";

  useEffect(() => {
    // Fetch league members and recent activity from Supabase
    const fetchLeagueData = async () => {
      try {
        // Fetch league members
        const { data: members, error: membersError } = await supabase
          .from("league_members")
          .select(
            `
            user_id, 
            joined_at, 
            users(username, avatar_url)
            `
          )
          .eq("league_id", leagueId);

        if (membersError) throw membersError;

        // Format member data
        const membersFormatted = members.map((member) => ({
          name: member.users?.username || "Unknown",
          avatar: member.users?.avatar_url || null,
          joinedAt: member.joined_at
            ? new Date(member.joined_at).getFullYear()
            : "Unknown",
        }));

        setLeagueMembers(membersFormatted);

        // Mock recent activity for now (replace with dynamic data)
        const activityMock = [
          "Joseph added Josh Allen to Milkanators.",
          "Landon traded Tyreek Hill to Waffle House.",
          "Derrick started Kenneth Walker III for Week 14.",
        ];
        setRecentActivity(activityMock);

        // Generate an invite link
        setInviteLink(`https://milkysports.com/join-league/${leagueId}`);
      } catch (error) {
        console.error("Error fetching league data:", error.message);
      }
    };

    fetchLeagueData();
  }, [leagueId]);

  const switchTab = (tab) => setActiveTab(tab);

  const copyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink);
    alert("Invite link copied to clipboard!");
  };

  const handleInviteMember = () => {
    alert("Send this link to invite members:\n" + inviteLink);
  };

  return (
    <div className="fantasy-league-management-container">
      {/* Header Section */}
      <header className="fantasy-league-management-header">
        <div className="fantasy-league-management-banner">
          <h1>{leagueName}</h1>
          <span className="fantasy-league-management-details">
            Sport: {sport} | Owner: {owner}
          </span>
        </div>
        <div className="invite-section">
          <button className="copy-link-button" onClick={copyInviteLink}>
            Copy Invite Link
          </button>
          <button className="invite-button" onClick={handleInviteMember}>
            <FiUserPlus /> Send Invite
          </button>
        </div>
      </header>

      {/* Navigation Tabs */}
      <nav className="fantasy-league-management-tabs">
        <button
          className={activeTab === "overview" ? "active" : ""}
          onClick={() => switchTab("overview")}
        >
          <MdOutlineSportsFootball /> Overview
        </button>
        <button
          className={activeTab === "draft" ? "active" : ""}
          onClick={() => switchTab("draft")}
        >
          Draft
        </button>
        <button
          className={activeTab === "teams" ? "active" : ""}
          onClick={() => switchTab("teams")}
        >
          Teams
        </button>
        <button
          className={activeTab === "standings" ? "active" : ""}
          onClick={() => switchTab("standings")}
        >
          <MdLeaderboard /> Standings
        </button>
        <button
          className={activeTab === "settings" ? "active" : ""}
          onClick={() => switchTab("settings")}
        >
          <FiSettings /> Settings
        </button>
      </nav>

      {/* Main Content */}
      <div className="fantasy-league-management-content">
        {activeTab === "overview" && (
          <div className="fantasy-league-management-overview-tab">
            <h2>Overview</h2>
            <p>Welcome to the Milky League dashboard! Manage your league here.</p>

            <h3>Recent Activity</h3>
            <ul className="recent-activity-list">
              {recentActivity.map((activity, index) => (
                <li key={index}>{activity}</li>
              ))}
            </ul>

            <h3>League Members</h3>
            <div className="league-members">
              {leagueMembers.map((member, index) => (
                <div key={index} className="league-member-card">
                  <div className="avatar">
                    {member.avatar ? (
                      <img src={member.avatar} alt={member.name} />
                    ) : (
                      <span className="default-avatar">{member.name[0]}</span>
                    )}
                  </div>
                  <div className="member-details">
                    <h4>{member.name}</h4>
                    <p>Since {member.joinedAt}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {activeTab === "draft" && (
          <div className="fantasy-league-management-draft-tab">
            <h2>Draft</h2>
            <p>The next draft is scheduled for Sunday at 7:00 PM.</p>
          </div>
        )}
        {activeTab === "teams" && (
          <div className="fantasy-league-management-teams-tab">
            <h2>Teams</h2>
            <div className="fantasy-league-management-teams-grid">
              {/* Add dynamic team content here */}
            </div>
          </div>
        )}
        {activeTab === "standings" && (
          <div className="fantasy-league-management-standings-tab">
            <h2>Standings</h2>
            <ol>
              {/* Add dynamic standings content here */}
            </ol>
          </div>
        )}
        {activeTab === "settings" && (
          <div className="fantasy-league-management-settings-tab">
            <h2>League Settings</h2>
            <form>
              <label>
                Allow Trades:
                <input type="checkbox" defaultChecked />
              </label>
              <label>
                Max Players Per Team:
                <input type="number" defaultValue={10} />
              </label>
              <button type="submit">Save Settings</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default FantasyLeagueManagement;
