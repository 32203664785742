// OrganizationManagement.js
import React, { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js"; // Import Supabase client
import "./OrganizationManagement.css"; // Import CSS for this component

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const OrganizationManagement = ({ organization, user }) => {
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [organizationSports, setOrganizationSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [organizationTeams, setOrganizationTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamPlayers, setTeamPlayers] = useState([]);

  // Fetch organization data when component mounts or organization changes
  useEffect(() => {
    const fetchOrganizationData = async () => {
      if (organization) {
        try {
          // Fetch organization members
          const { data: members, error: membersError } = await supabase
            .from("orgmembers")
            .select("*, users:user_id ( username, email )")
            .eq("organization_id", organization.organization_id);

          if (membersError) throw membersError;
          setOrganizationMembers(members);

          // Fetch sports offered by the organization
          const { data: sports, error: sportsError } = await supabase
            .from("sports")
            .select("*")
            .eq("organization_id", organization.organization_id);

          if (sportsError) throw sportsError;
          setOrganizationSports(sports);

          // Reset selected sport, team, and team players
          setSelectedSport(null);
          setOrganizationTeams([]);
          setSelectedTeam(null);
          setTeamPlayers([]);
        } catch (error) {
          console.error("Error fetching organization data:", error.message);
        }
      }
    };

    fetchOrganizationData();
  }, [organization]);

  // Handle selecting a sport
  const handleSportSelect = async (sport) => {
    setSelectedSport(sport);
    setSelectedTeam(null);
    setTeamPlayers([]);
    try {
      // Fetch teams for the selected sport in the organization
      const { data: teams, error: teamsError } = await supabase
        .from("orgteams")
        .select("*")
        .eq("organization_id", organization.organization_id)
        .eq("sport_id", sport.sport_id);

      if (teamsError) throw teamsError;
      setOrganizationTeams(teams);
    } catch (error) {
      console.error("Error fetching teams:", error.message);
    }
  };

  // Handle selecting a team
  const handleTeamSelect = async (team) => {
    setSelectedTeam(team);
    try {
      // Fetch players for the selected team
      const { data: players, error: playersError } = await supabase
        .from("orgteamplayers")
        .select("*")
        .eq("team_id", team.team_id);

      if (playersError) throw playersError;
      setTeamPlayers(players);
    } catch (error) {
      console.error("Error fetching team players:", error.message);
    }
  };

  // Handle changing member permissions
  const handlePermissionChange = async (memberId, newRole) => {
    try {
      const { error } = await supabase
        .from("orgmembers")
        .update({ role: newRole })
        .eq("member_id", memberId);

      if (error) throw error;

      // Update the local state
      setOrganizationMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.member_id === memberId ? { ...member, role: newRole } : member
        )
      );
    } catch (error) {
      console.error("Error updating member role:", error.message);
    }
  };

  // Check if user is organization owner
  const isOwner = user && organization && user.id === organization.owner_id;

  // Functions to add/delete sports, teams, and players
  // Add Sport
  const handleAddSport = async () => {
    const sportName = prompt("Enter the sport name:");
    if (sportName) {
      try {
        const { data, error } = await supabase.from("sports").insert([
          {
            organization_id: organization.organization_id,
            sport_name: sportName,
            created_at: new Date(),
            is_custom: true,
          },
        ]);
        if (error) throw error;
        setOrganizationSports([...organizationSports, ...data]);
      } catch (error) {
        console.error("Error adding sport:", error.message);
      }
    }
  };

  // Delete Sport
  const handleDeleteSport = async (sportId) => {
    if (window.confirm("Are you sure you want to delete this sport?")) {
      try {
        const { error } = await supabase
          .from("sports")
          .delete()
          .eq("sport_id", sportId);

        if (error) throw error;

        setOrganizationSports(
          organizationSports.filter((sport) => sport.sport_id !== sportId)
        );
        if (selectedSport && selectedSport.sport_id === sportId) {
          setSelectedSport(null);
          setOrganizationTeams([]);
          setSelectedTeam(null);
          setTeamPlayers([]);
        }
      } catch (error) {
        console.error("Error deleting sport:", error.message);
      }
    }
  };

  // Add Team
  const handleAddTeam = async () => {
    const teamName = prompt("Enter the team name:");
    if (teamName && selectedSport) {
      try {
        const { data, error } = await supabase.from("orgteams").insert([
          {
            organization_id: organization.organization_id,
            sport_id: selectedSport.sport_id,
            name: teamName,
            created_at: new Date(),
          },
        ]);
        if (error) throw error;
        setOrganizationTeams([...organizationTeams, ...data]);
      } catch (error) {
        console.error("Error adding team:", error.message);
      }
    }
  };

  // Delete Team
  const handleDeleteTeam = async (teamId) => {
    if (window.confirm("Are you sure you want to delete this team?")) {
      try {
        const { error } = await supabase
          .from("orgteams")
          .delete()
          .eq("team_id", teamId);

        if (error) throw error;

        setOrganizationTeams(
          organizationTeams.filter((team) => team.team_id !== teamId)
        );
        if (selectedTeam && selectedTeam.team_id === teamId) {
          setSelectedTeam(null);
          setTeamPlayers([]);
        }
      } catch (error) {
        console.error("Error deleting team:", error.message);
      }
    }
  };

  // Add Player
  const handleAddPlayer = async () => {
    const playerName = prompt("Enter the player's name:");
    const position = prompt("Enter the player's position:");
    if (playerName && position && selectedTeam) {
      try {
        const { data, error } = await supabase.from("orgteamplayers").insert([
          {
            team_id: selectedTeam.team_id,
            organization_id: organization.organization_id,
            player_name: playerName,
            position: position,
            created_at: new Date(),
          },
        ]);
        if (error) throw error;
        setTeamPlayers([...teamPlayers, ...data]);
      } catch (error) {
        console.error("Error adding player:", error.message);
      }
    }
  };

  // Delete Player
  const handleDeletePlayer = async (playerId) => {
    if (window.confirm("Are you sure you want to delete this player?")) {
      try {
        const { error } = await supabase
          .from("orgteamplayers")
          .delete()
          .eq("player_id", playerId);

        if (error) throw error;

        setTeamPlayers(
          teamPlayers.filter((player) => player.player_id !== playerId)
        );
      } catch (error) {
        console.error("Error deleting player:", error.message);
      }
    }
  };

  return (
    <div className="organization-content">
      <h1>{organization.name}</h1>
      {/* Organization Details */}
      {/* Members Management */}
      <div className="members-management">
        <h2>Members</h2>
        <table>
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
              {isOwner && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {organizationMembers.map((member) => (
              <tr key={member.member_id}>
                <td>{member.users.username}</td>
                <td>{member.users.email}</td>
                <td>{member.role}</td>
                {isOwner && (
                  <td>
                    <select
                      value={member.role}
                      onChange={(e) =>
                        handlePermissionChange(member.member_id, e.target.value)
                      }
                    >
                      <option value="owner">Owner</option>
                      <option value="admin">Admin</option>
                      <option value="member">Member</option>
                    </select>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Sports offered */}
      <div className="sports-offered">
        <h2>Sports</h2>
        <ul>
          {organizationSports.map((sport) => (
            <li key={sport.sport_id}>
              <span onClick={() => handleSportSelect(sport)}>
                {sport.sport_name}
              </span>
              {isOwner && (
                <button
                  className="delete-button"
                  onClick={() => handleDeleteSport(sport.sport_id)}
                >
                  Delete
                </button>
              )}
            </li>
          ))}
        </ul>
        {isOwner && (
          <button className="add-button" onClick={handleAddSport}>
            Add Sport
          </button>
        )}
      </div>
      {/* Teams under selected sport */}
      {selectedSport && (
        <div className="teams-under-sport">
          <h3>Teams in {selectedSport.sport_name}</h3>
          <ul>
            {organizationTeams.map((team) => (
              <li key={team.team_id}>
                <span onClick={() => handleTeamSelect(team)}>{team.name}</span>
                {isOwner && (
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteTeam(team.team_id)}
                  >
                    Delete
                  </button>
                )}
              </li>
            ))}
          </ul>
          {isOwner && (
            <button className="add-button" onClick={handleAddTeam}>
              Add Team
            </button>
          )}
        </div>
      )}
      {/* Players in selected team */}
      {selectedTeam && (
        <div className="team-players">
          <h4>Players in {selectedTeam.name}</h4>
          <ul>
            {teamPlayers.map((player) => (
              <li key={player.player_id}>
                {player.player_name} - {player.position}
                {isOwner && (
                  <button
                    className="delete-button"
                    onClick={() => handleDeletePlayer(player.player_id)}
                  >
                    Delete
                  </button>
                )}
              </li>
            ))}
          </ul>
          {isOwner && (
            <button className="add-button" onClick={handleAddPlayer}>
              Add Player
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default OrganizationManagement;
